import { render, staticRenderFns } from "./ChangePasswordComponent.vue?vue&type=template&id=45d27486&scoped=true&"
import script from "./ChangePasswordComponent.vue?vue&type=script&lang=js&"
export * from "./ChangePasswordComponent.vue?vue&type=script&lang=js&"
import style0 from "./ChangePasswordComponent.vue?vue&type=style&index=0&id=45d27486&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d27486",
  null
  
)

export default component.exports