<template>
  <div id="securityManagement">
    <p class="sm_title f-22 fw-900">{{ $t('security.securityManagement') }}</p>
    <div class="sm_main">
      <div class="main_white">
        <AuthenticationNote :showButton="false" />
        <h1 class="fw-800 f-16 main_title">{{ $t('security.authenticationMethod') }}</h1>
        <div class="sm_main_body">
          <!--email-->
          <div class="authentication_box">
            <div class="authentication_main">
              <div class="main_box left">
                <div class="title_status">
                  <p class="f-16 fw-500 box_title">{{ $t('common.field.emailAdd') }}</p>

                  <div v-if="emailVerified" class="verify_box">
                    <img src="@/assets/images/security/checked.svg" alt="" />
                    <p class="fw-400 f-12">{{ $t('security.isVerified') }}</p>
                  </div>
                  <div v-else class="verify_box">
                    <img src="@/assets/images/security/unchecked.svg" alt="" />
                    <p class="fw-400 f-12">{{ $t('security.isNotVerified') }}</p>
                  </div>
                </div>
                <div class="fw-400 f-14">{{ email }}</div>
                <div class="fw-400 f-12 gray_text" v-if="emailUpdateDate">
                  {{ `${$t('security.lastUpdate')}: ${emailUpdateDate}` }}
                </div>
              </div>
              <div class="right">
                <el-button
                  v-if="emailVerified"
                  :class="`btn-default ${langClass}`"
                  @click="actionTaken('modify-email')"
                  data-testid="modify"
                >
                  {{ $t('security.modify') }}
                </el-button>
                <el-button v-else :class="`btn-default ${langClass}`" @click="verifyEmail()" data-testid="verify">
                  {{ $t('security.verify') }}
                </el-button>
              </div>
            </div>

            <div class="border"></div>
            <div class="switch_status f-14 fw-400">
              <div class="switch_status_sub" v-for="(item, index) in dynamicSwitches?.['email']" :key="index">
                <p>{{ item.translateValue }}</p>
                <el-switch
                  :disabled="item.disabled"
                  v-model="item.value"
                  @change="changeSwitch($event, 'email', item.authType, item?.factorAuth)"
                ></el-switch>
              </div>
              <div class="switch_status_sub">
                <p>{{ $t('security.loginLocationChange') }}</p>
                <el-switch v-model="ipChangeWarn" @change="changeIpWarningChange"></el-switch>
              </div>
            </div>
            <div class="border"></div>
            <div class="authentication_main">
              <div class="main_box left">
                <div class="title f-16 fw-500 box_title">{{ $t('security.preferredLang.title') }}</div>
                <div class="desc fw-400 f-12 gray_text">
                  <span>{{ $t('security.preferredLang.currentLang', { lang: currentLangLabel }) }}</span>
                  <span v-if="isDefaultLang">{{ `(${$t('security.preferredLang.default')})` }}</span>
                </div>
              </div>
              <div class="right">
                <el-button :class="`btn-default ${langClass}`" @click="changePreferredLanguage">
                  {{ $t('security.modify') }}
                </el-button>
              </div>
            </div>
          </div>
          <!--password-->
          <div class="authentication_box">
            <div class="authentication_main">
              <div class="main_box left">
                <p class="f-16 fw-500 box_title">{{ $t('security.password') }}</p>
                <div class="fw-400 f-12 gray_text" v-if="passwordUpdateDate">
                  {{ `${$t('security.lastUpdate')}: ${passwordUpdateDate}` }}
                </div>
              </div>
              <div class="right">
                <el-button
                  :class="`btn-default ${langClass}`"
                  @click="actionTaken('modify-password')"
                  data-testid="modify"
                >
                  {{ $t('security.modify') }}
                </el-button>
              </div>
            </div>
          </div>
          <!--totp-->
          <div class="authentication_box">
            <div class="authentication_main">
              <div class="main_box left">
                <div class="title_status">
                  <p class="f-16 fw-500 box_title">{{ $t('security.securityAuthenticatorApp') }}</p>

                  <div v-if="totpVerified" class="verify_box">
                    <img src="@/assets/images/security/checked.svg" alt="" />
                    <p class="fw-400 f-12">{{ $t('security.isVerified') }}</p>
                  </div>
                  <div v-else class="verify_box">
                    <img src="@/assets/images/security/unchecked.svg" alt="" />
                    <p class="fw-400 f-12">{{ $t('security.isNotVerified') }}</p>
                  </div>
                </div>

                <div class="fw-400 f-12 gray_text" v-if="totpUpdateDate">
                  {{ `${$t('security.lastUpdate')}: ${totpUpdateDate}` }}
                </div>
              </div>
              <div class="right">
                <el-button
                  v-if="totpVerified"
                  :class="`btn-default ${langClass}`"
                  @click="actionTaken('modify-auth-2fa')"
                  data-testid="modify"
                >
                  {{ $t('security.modify') }}
                </el-button>
                <el-button
                  v-else
                  :class="`btn-default ${langClass}`"
                  @click="actionTaken('enable-auth-2fa')"
                  data-testid="enable"
                >
                  {{ $t('security.enable') }}
                </el-button>
              </div>
            </div>

            <div v-if="dynamicSwitches?.['totp']?.length > 0" class="border"></div>
            <div class="switch_status f-14 fw-400">
              <div class="switch_status_sub" v-for="(item, index) in dynamicSwitches?.['totp']" :key="index">
                <p>{{ item.translateValue }}</p>
                <el-switch
                  :disabled="item.disabled"
                  v-model="item.value"
                  @change="changeSwitch($event, 'totp', item.authType, item?.factorAuth)"
                ></el-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--dialog-->
    <el-dialog
      @keydown.enter.native.prevent
      @close="closeDialog"
      :id="currentComponent?.class"
      :visible="dialogVisibility"
      :showCancel="true"
      center
      class="dialog"
      top="0"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <slot name="header"> </slot>
      </div>
      <div class="dialog-body">
        <slot>
          <component
            ref="component"
            :is="currentComponent?.component"
            :showDynamicComponent="showDynamicComponent"
            :usedAuthTypes="usedAuthTypes"
            :action="showDynamicComponent?.factorAuth"
            :successNote="showDynamicComponent?.success"
            @confirm="confirmChanges"
            @changeVisible="closeDialog"
            @reauthenticate="requireReauthenticate"
            :showDays="showDays"
        /></slot>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
    <VerificationDialog
      ref="verification"
      :dialogVisible.sync="verificationVisibility"
      :usedAuthTypes="usedAuthTypes"
      :action="showDynamicComponent?.factorAuth"
      @changeVisible="updateVerificationVisible"
      @confirm="confirmVerification"
    ></VerificationDialog>
    <ChangePreferredLanguage
      ref="changePreferredLanguageRef"
      :currentLang="currentLang"
      @confirm="getInfo"
    ></ChangePreferredLanguage>
  </div>
</template>

<script>
import ChangeAuthenticatorComponent from '@/components/security/ChangeAuthenticatorComponent.vue';
import ConfirmationComponent from '@/components/security/ConfirmationComponent.vue';
import VerificationDialog from '@/components/security/VerificationDialog.vue';
import SuccessComponent from '@/components/security/SuccessComponent.vue';
import ChangePasswordComponent from '@/components/security/ChangePasswordComponent.vue';
import ChangeEmailComponent from '@/components/security/ChangeEmailComponent.vue';
import ChangePreferredLanguage from '@/components/security/ChangePreferredLanguage.vue';
import { mapOperation, mapVariable, mapSwitchComponent, mapVerifiedAuth, langOptions } from '@/components/security/Security.js';
import AuthenticationNote from '@/components/security/AuthenticationNote';
import {
  apiSubmitResetChangeIpWarn,
  apiGetSecuritysRule,
  apiGetSecurityInfo,
  apiChangeSwitch,
  apiGetMultiFactorAuth,
  apiVerifyEmail
} from '@/resource';
import moment from 'moment';
import helper from '@/util/signinHelper.js';

export default {
  props: {},
  components: {
    ConfirmationComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    SuccessComponent,
    ChangeAuthenticatorComponent,
    VerificationDialog,
    ChangePreferredLanguage,
    AuthenticationNote
  },
  data() {
    return {
      currentLang: '',
      isDefaultLang: true,
      dialogVisibility: false,
      verificationVisibility: false,
      showDays: null,
      showDynamicComponent: null,
      usedAuthTypes: [],
      ipChangeWarn: false,
      email: '',
      emailUpdateDate: null,
      passwordUpdateDate: null,
      totpUpdateDate: null,
      dynamicSwitches: {},
      currentComponent: null,
      isReauthenticate: false,
      actionComponentMap: {
        'modify-password': {
          class: 'ChangePasswordComponent',
          component: ChangePasswordComponent
        },
        'modify-email': {
          class: 'ChangeEmailComponent',
          component: ChangeEmailComponent
        },
        'enable-auth-2fa': {
          class: 'ChangeAuthenticatorComponent',
          component: ChangeAuthenticatorComponent
        },
        'modify-auth-2fa': {
          class: 'ChangeAuthenticatorComponent',
          component: ChangeAuthenticatorComponent
        }
      }
    };
  },

  computed: {
    emailVerified() {
      return this.checkedAuthStatus.filter(item => item == mapVariable['email'])?.length > 0;
    },
    totpVerified() {
      return this.checkedAuthStatus.filter(item => item == mapVariable['totp'])?.length > 0;
    },
    checkedAuthStatus() {
      return this.$store.state.common.checkedAuthStatus || [];
    },
    langClass() {
      return ['en_US', 'es', 'vi', 'ar'].includes(this.$store.state.common.lang)
        ? `${this.$store.state.common.lang}_buttons`
        : '';
    },
    currentLangLabel() {
      return langOptions().find(item => item.value === this.currentLang)?.label
    }
  },
  async mounted() {
    this.getInfo();
  },
  methods: {
    changePreferredLanguage() {
      this.$refs.changePreferredLanguageRef.openDialog();
    },
    async getInfo() {
      await apiGetSecurityInfo().then(resp => {
        if (resp.data.code == 0) {
          const usedValue = resp.data.data;
          this.ipChangeWarn = usedValue.ipChangeSwitch;
          this.email = usedValue.email;
          this.emailUpdateDate = this.formatTime(usedValue.emailUpdateDate);
          this.passwordUpdateDate = this.formatTime(usedValue.passwordUpdateDate);
          this.totpUpdateDate = this.formatTime(usedValue.totpUpdateDate);
          this.$store.commit('common/setCheckedAuthStatus', usedValue.authStatus);
          this.$store.commit('common/setEmailMasked', usedValue.email);
          this.isDefaultLang = !!usedValue.preferredLanguage ? false : true
          this.currentLang = usedValue.preferredLanguage || 'en_US'

          //do ignore switches except login, and login.
          //BE mention will do enhancement in the future (won't return in the future)
          this.dynamicSwitches =
            usedValue.functionAuthMethod &&
            Object.keys(usedValue.functionAuthMethod).reduce((acc, functionName) => {
              usedValue.functionAuthMethod[functionName].forEach(authMethod => {
                if (!acc[authMethod]) {
                  acc[authMethod] = [];
                }

                const tempMapping = mapSwitchComponent(functionName, authMethod);

                if (tempMapping != null) {
                  const usedObject = {
                    authType: functionName,
                    translateValue: tempMapping?.title,
                    disabled:
                      usedValue.mandatoryFunctions.filter(item => item == functionName)?.length > 0 ||
                      !mapVerifiedAuth(authMethod, usedValue.authStatus),
                    value: usedValue?.[tempMapping?.usedVariable]?.[authMethod] || false,
                    factorAuth: tempMapping?.factorAuth
                  };

                  acc[authMethod].push(usedObject);
                }
              });
              return acc;
            }, {});
        }
      });
    },
    async getDays(factor) {
      //get to show the related confirmation dialogs
      const factorAuth =
        factor == 'modify-password'
          ? 'updatePassword'
          : ['enable-auth-2fa', 'modify-auth-2fa'].includes(factor)
          ? 'changeAuthenticator'
          : 'updateUserLogin';
      await apiGetSecuritysRule({ functionCode: factorAuth }).then(resp => {
        this.showDays = resp?.data?.data?.ruleContent?.withdraw?.limitTime || null;
      });
    },
    formatTime(timestamp) {
      if (!timestamp) {
        return null;
      }
      const localtime = moment(timestamp);
      return localtime.format('YYYY/MM/DD HH:mm:ss') + ' GMT' + localtime.format('Z');
    },
    async actionTaken(factorAuth) {
      //modify actions
      await this.getDays(factorAuth);
      this.showDynamicComponent = mapOperation(factorAuth, this.showDays);
      if (factorAuth != 'enable-auth-2fa' && this.showDays != null) {
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.dialogVisibility = true;
      } else {
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.confirmChanges();
      }
    },
    async verifyEmail() {
      this.showDynamicComponent = {
        factorAuth: 'verify-email'
      };
      this.needAuthentication(this.showDynamicComponent?.factorAuth);
    },
    async changeSwitch(value, authMethod, authFunction, factorAuth) {
      const requestBody = { authFunction: authFunction, authMethod: authMethod, authSwitch: value };

      this.showDynamicComponent = {
        factorAuth: factorAuth,
        action: factorAuth,
        request: requestBody
      };
      if (value == false) {
        const needAuthentication = await this.needAuthentication(factorAuth);
        if (!needAuthentication) {
          await this.updateSwitchApi(requestBody);
        }
      } else {
        await this.updateSwitchApi(requestBody);
      }
    },
    async changeIpWarningChange(value) {
      await apiSubmitResetChangeIpWarn({ changeIpWarn: value }).then(async resp => {
        if (resp.data.code == 0) {
          this.ipChangeWarn = value;
          this.$message({ message: this.$t('security.updateSuccess'), type: 'success' });
          await this.getInfo();
        }
      });
    },

    async updateSwitchApi(requestBody) {
      await apiChangeSwitch(requestBody).then(resp => {
        if (resp.data.code == 0) {
          this.$message({ message: this.$t('security.updateSuccess'), type: 'success' });
          this.getInfo();
        }
      });
    },
    async getRelatedAuthTypes(factorCode) {
      if (!factorCode) return;
      let tempAuthTypes = [];

      if (factorCode == 'verify-email') {
        tempAuthTypes = [
          {
            type: 'email',
            authenticated: false,
            verified: true
          }
        ];
        let usedRequiredAuths = this.$store.state.common.requiredAuths;
        usedRequiredAuths[factorCode] = ['email'];
        this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
      } else {
        await apiGetMultiFactorAuth(factorCode).then(resp => {
          if (resp.data.code == 0) {
            tempAuthTypes =
              resp.data.data.authType != null
                ? resp.data.data.authType.map(item => {
                    return {
                      type: item,
                      authenticated: false,
                      verified: mapVerifiedAuth(item, this.$store.state.common.checkedAuthStatus)
                    };
                  })
                : [];
            let usedRequiredAuths = this.$store.state.common.requiredAuths;
            usedRequiredAuths[factorCode] = resp.data.data.authType;
            this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
          }
        });
      }
      return tempAuthTypes;
    },
    closeDialog() {
      if (this.currentComponent?.class == 'SuccessComponent') {
        if (
          this.showDynamicComponent?.factorAuth == 'modify-password' ||
          this.showDynamicComponent?.factorAuth == 'modify-email'
        ) {
          helper.signOut(null, 'modifyAccount');
        } else {
          this.getInfo();
        }
      }
      this.dialogVisibility = false;
    },
    async confirmChanges() {
      this.dialogVisibility = false;
      let usedAction = this.showDynamicComponent?.factorAuth;
      switch (this.currentComponent?.class) {
        case 'ConfirmationComponent':
          const needAuthentication = await this.needAuthentication(usedAction);
          if (!needAuthentication) {
            this.confirmVerification();
          }
          break;
        case 'ChangeEmailComponent':
        case 'ChangePasswordComponent':
        case 'ChangeAuthenticatorComponent': {
          this.currentComponent = {
            class: 'SuccessComponent',
            component: SuccessComponent
          };
          this.dialogVisibility = true;
          break;
        }
      }
    },
    resetSwitch() {
      if (
        this.showDynamicComponent?.factorAuth == 'login-email-close' ||
        this.showDynamicComponent?.factorAuth == 'login-totp-close'
      ) {
        const tempRequest = this.showDynamicComponent?.request;

        this.dynamicSwitches[tempRequest?.authMethod] = this.dynamicSwitches[tempRequest?.authMethod].map(item => {
          return {
            ...item,
            value: item?.authType == tempRequest?.authFunction ? !tempRequest?.authSwitch : item.value
          };
        });
      }
    },
    /** verification */
    requireReauthenticate(val) {
      this.isReauthenticate = true;
      this.usedAuthTypes = val;
      this.handleAuthentication();
    },
    updateVerificationVisible() {
      this.verificationVisibility = false;
      this.resetSwitch();
    },
    async confirmVerification() {
      //proceed with next dialog or action after verification
      this.verificationVisibility = false;
      let usedAction = this.showDynamicComponent?.factorAuth;
      if (
        this.showDynamicComponent?.factorAuth == 'login-email-close' ||
        this.showDynamicComponent?.factorAuth == 'login-totp-close'
      ) {
        this.updateSwitchApi(this.showDynamicComponent?.request);
      } else if (usedAction == 'verify-email') {
        this.dialogVisibility = false;
        apiVerifyEmail().then(async resp => {
          if (resp.data.code == 0) {
            this.$message({
              message: this.$t('security.verifiedSuccess'),
              type: 'success',
              duration: 5000
            });
            await this.getInfo();
          } else {
            return;
          }
        });
      } else {
        if (this.dialogVisibility == true && this.isReauthenticate) {
          this.$refs.component.submitForm();
        } else if (this.actionComponentMap.hasOwnProperty(usedAction)) {
          this.currentComponent = this.actionComponentMap[usedAction];
          this.dialogVisibility = true;
        }
      }
      this.isReauthenticate = false;
    },
    async needAuthentication(factorAuth) {
      //get related authentication required
      this.usedAuthTypes = await this.getRelatedAuthTypes(factorAuth);
      return this.handleAuthentication();
    },
    async handleAuthentication() {
      if (this.usedAuthTypes.every(item => item.verified == true) == false) {
        this.resetSwitch();
        this.showDynamicComponent = {
          title: this.$t('security.verificationTitle'),
          desc: this.$t('security.verificationDesc'),
          confirmButton: null,
          cancelButton: this.$t('common.button.confirm')
        };
        this.currentComponent = {
          class: 'ConfirmationComponent',
          component: ConfirmationComponent
        };
        this.dialogVisibility = true;
        return true;
      }
      if (this.usedAuthTypes && this.usedAuthTypes.length > 0) {
        this.verificationVisibility = true;
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';

/deep/ .el-dialog {
  margin-top: 0vh !important;
  border-radius: 16px;
  padding: 40px 40px;
  height: auto;
  max-height: 85vh;
  overflow-y: scroll;
}

/deep/ .el-dialog__header {
  padding: 0px;
}

/deep/ .el-dialog__body {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  padding-bottom: 0px;
  .el-dialog__body {
    text-align: start;
  }

  .title {
    padding-bottom: 20px;
    color: black;
  }

  .body {
    padding-bottom: 20px;
  }
}

/deep/ .el-dialog__footer {
  padding: 0px;
}

.verificationDialog /deep/ .el-dialog {
  max-width: 500px;
}

#ConfirmationComponent {
  /deep/ .el-dialog {
    padding: 40px 50px;
    max-width: 480px;
    .el-dialog__body {
      text-align: center;
      .body {
        color: #5b5b5b;
      }
    }
  }
}

#ChangePasswordComponent {
  /deep/ .el-dialog {
    max-width: 480px;
  }
}

#ChangeEmailComponent {
  /deep/ .el-dialog {
    max-width: 500px;
  }
}

#VerificationComponent {
  /deep/ .el-dialog {
    max-width: 480px;
  }
}

#ChangeAuthenticatorComponent {
  /deep/ .el-dialog {
    max-width: 757px;
  }
}

#SuccessComponent {
  /deep/ .el-dialog {
    padding: 40px 50px;
    max-width: 480px;
    .el-dialog__body {
      text-align: center;
    }
  }
}

.en_US_buttons {
  min-width: 90.85px;
}

.es_buttons {
  min-width: 106.39px;
}

.vi_buttons {
  min-width: 106.16px;
}

.ar_buttons {
  min-width: 80.35px;
}
</style>
