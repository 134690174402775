<template>
  <div id="authentication_note" class="authentication_note" v-if="shownNote != null">
    <div><span v-html="shownNote"></span></div>
    <div class="note_button" v-if="showButton">
      <el-button class="btn-blue" @click="goToSecurity" data-testid="goToSecurity">
        {{ $t('security.enableNow') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { apiGetHint } from '@/resource';
export default {
  props: {
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      shownNote: null
    };
  },
  mounted() {
    this.getHint();
  },
  methods: {
    goToSecurity() {
      this.$router.push('/securityManagement');
    },
    getHint() {
      apiGetHint().then(resp => {
        this.shownNote = resp.data.data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/security.scss';
</style>
