<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="480px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <el-form label-position="top" :model="formData" v-if="!isResult">
      <h3 class="title">{{ $t('security.preferredLang.dialogTitle') }}</h3>
      <p class="desc">{{ $t('security.preferredLang.dialogDesc') }}</p>
      <el-form-item :label="$t('security.preferredLang.language')" prop="language">
        <el-select v-model="formData.language" :placeholder="$t('common.field.select')">
          <el-option v-for="item in langOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <div class="buttons_class">
        <el-button class="btn-default" @click="onCancel"> {{ $t('common.button.cancel') }} </el-button>
        <el-button class="btn-blue" @click="onConfirm"> {{ $t('common.button.confirm') }} </el-button>
      </div>
    </el-form>
    <div class="result_wrapper" v-else>
      <img src="@/assets/images/dialog_success.png" alt />
      <h3 class="result_title">{{ $t('security.preferredLang.resultTitle') }}</h3>
      <!-- <p class="result_desc">{{ $t('security.preferredLang.resultDesc', { lang: currentLangLabel }) }}</p> -->
      <p class="result_desc">
        <i18n path="security.preferredLang.resultDesc" tag="div">
          <template v-slot:lang>
            <span class="special_lang">{{ currentLangLabel }}</span>
          </template>
        </i18n>
      </p>
    </div>
  </el-dialog>
</template>

<script>
import { apiChangePreferredLanguage } from '@/resource';
import { langOptions } from '@/components/security/Security.js';
export default {
  name: 'ChangePreferredLanguage',
  props: {
    currentLang: {
      type: String,
      default: 'en_US'
    }
  },
  data() {
    return {
      dialogVisible: false,
      isResult: false,
      formData: {
        language: ''
      }
    };
  },
  computed: {
    langOptions() {
      return langOptions();
    },
    currentLangLabel() {
      return this.langOptions.find(f => f.value === this.formData.language)?.label;
    }
  },
  methods: {
    openDialog() {
      this.isResult = false;
      this.formData.language = this.currentLang;
      this.dialogVisible = true;
    },
    onCancel() {
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
      if (this.isResult) {
        this.$emit('confirm');
      }
    },
    async onConfirm() {
      try {
        const query = {
          userId: this.$store.state.common.userId,
          language: this.formData.language
        };
        const res = await apiChangePreferredLanguage(query);
        if (res && res.data && res.data.code === 0) {
          this.isResult = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.title {
  font-size: 20px;
  font-weight: 800;
}
.desc {
  padding-bottom: 20px;
  color: #5b5b5b;
}
.buttons_class {
  display: flex;
  gap: 20px;
  /deep/ .el-button {
    border-radius: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    padding: 11px !important;
    margin-top: 32px;
  }
}
.result_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 89px;
  }
  .result_title {
    font-size: 20px;
    font-weight: 800;
    padding: 20px 0;
    color: $black;
  }
  .result_desc {
    word-break: break-word;
    text-align: center;
    color: #5b5b5b;
  }
}
.special_lang {
  color: $pug-blue;
}
</style>
