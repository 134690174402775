<template>
  <div>
    <div class="title">
      <slot name="header">
        <p class="fw-800 f-16">
          {{ $t('propTrade.button.changePwd') }}
        </p>
      </slot>
    </div>
    <div class="body">
      <div class="word_break">
        <div class="requirement_box">
          <p>{{ $t('security.passwordReq') }}</p>
          <ul>
            <li>{{ $t('security.passwordCharacters') }}</li>
            <li>{{ $t('security.passwordMix') }}</li>
            <li>{{ $t('security.passwordSpecial') }}</li>
          </ul>
        </div>
        <el-form label-position="top" :model="changePasswordForm" :rules="rulesChangePassword" ref="changePasswordForm">
          <el-form-item :label="$t('changePw.field.currPw')" prop="currentPass">
            <el-input
              type="password"
              v-model="changePasswordForm.currentPass"
              autocomplete="off"
              show-password
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
            <el-input
              type="password"
              v-model="changePasswordForm.pass"
              autocomplete="off"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('security.confirmPwd')" prop="checkPass">
            <el-input
              type="password"
              v-model="changePasswordForm.checkPass"
              autocomplete="off"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="footer">
      <div class="footer_body">
        <el-button class="btn-blue" @click="submitForm('changePasswordForm')" data-testid="changePw">
          {{ $t('common.button.confirm') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import { encrypt } from '@/util/rsa/JSEncrypt.js';
import { apiCheckPassword, apiUpdatePassword, apiEncryptPublicKey } from '@/resource';
import { mapReverifiedBody } from '@/components/security/Security.js';
export default {
  name: 'ChangePasswordComponent',
  props: ['dialogVisible'],
  data() {
    const validateCurrentPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        apiCheckPassword({
          currentPass: md5(value)
        }).then(resp => {
          if (!resp.data) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            callback();
          }
        });
      }
    };

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        } else {
          apiCheckPassword({
            currentPass: md5(value)
          }).then(resp => {
            if (resp.data) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')));
            } else {
              callback();
            }
          });
        }
      }
    };

    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.changePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };

    return {
      visible: false,
      rulesChangePassword: {
        currentPass: [{ validator: validateCurrentPass, trigger: ['blur'] }],
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validateCheckPass, trigger: ['blur', 'change'] }]
      },
      changePasswordForm: {
        currentPass: '',
        pass: '',
        checkPass: ''
      }
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    }
  },
  methods: {
    close() {
      this.$emit('changeVisible', false);
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    confirm() {
      this.$emit('confirm');
    },
    submitForm() {
      this.$refs.changePasswordForm.validate(async valid => {
        if (valid) {
          let result = await apiEncryptPublicKey();
          let publicKey = result.data.data;
          apiUpdatePassword(
            {
              currentPass: md5(this.changePasswordForm.currentPass),
              pass: encrypt(this.changePasswordForm.pass, publicKey),
              checkPass: encrypt(this.changePasswordForm.checkPass, publicKey)
            },
            publicKey
          ).then(resp => {
            if (resp.data.code === 0) {
              this.confirm();
            } else if (resp.data.code == 1201 || resp.data.code == 1202) {
              const usedRequest = mapReverifiedBody('modify-password', resp);
              this.$emit('reauthenticate', usedRequest);
            }
          });
        } else return false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/security.scss';

ul {
  color: rgba(35, 32, 32, 1);
  @include rtl-sass-prop(padding-left, padding-right, 20px);

  li {
    font-size: 10px;
    line-height: 1.5;
    list-style: disc !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.footer {
  padding-top: 16px;
  .footer_body {
    display: flex;
    gap: 20px;
    .el-button {
      border-radius: 8px;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      padding: 11px;
    }
  }
}
</style>
